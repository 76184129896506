.nav {
    //height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navhome {
    margin-left: 5vw;
    display: flex;
    align-items: center;
  }

  .navhome span {
    color: #747487;
    display: inline-block;
    padding-left: 1rem;
    margin-left: 1rem;
    border-left-width: 1px;
    border-color: #747487;
  }
  
  
  .navdoc {
    flex-grow: 1;
  }
  
  .navleave {
    margin-right: 2rem;
  }
  
  
  .navdoc span {
    margin: 0 10px 0 0;
    padding-left: 1rem;
    border-left-width: 1px;
    border-color: #747487;
  }
  
  
  
  .home {
    padding-top: 10vh;
    h1{
      font-size: 32px;
      margin-bottom: 20px;
    }
    .feature-entry {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .entry-item{
        margin: 0 20px 50px 0;
        .ant-card-cover {
          padding-top:20px
        }
      }
    }
  }
  