.viewport {
  position: absolute;
  top: 80px;
  bottom: 52px;
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  color: white;
  .video-container {
    // width: 100%;
    // height: 100%;
    .video-canvas{
      // height: 100%;
      // width: 100%;
    }
  }
  .avatar-list {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  .video-operations {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .avatar-wrap{
    position: absolute;
    pointer-events: auto;
    list-style: none;
    top:0;
    left:0;
  }
  .avatar-wrap-dragging{
    z-index: 10;
  }
  .single-view-avatar {
    top:0;
    left: 0;
  }
}