.App {
  background-color: #282c34;
  text-align: center;
  height: 100%;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  z-index: 500;
}
