.video-footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;

  .navleave {
    background-color: red;
    color: white;
  }
}