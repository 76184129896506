.landing-box {
  position: absolute;
  top: 200px;
  bottom: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
}

.welcome {
  padding: 50px;
  margin: auto;
  border: 1px solid black;
  background-color: #282c34;
  font-size: 30px;
  > *{
    font-size: 28px !important;
  }
};

.button {
  color: white !important;
  border: 1px solid white !important;
}